import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FormattedMessage, useIntl } from 'react-intl'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Content from '../components/Content'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import Label from '../components/Label'

const options = {
  renderText: text => text.split('\n').flatMap((t, i) => [i > 0 && <br />, t]),
}

const ImprintPage = ({ data }) => {
  const intl = useIntl()
  const { title, content } = data.allContentfulPage.edges[0].node

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'imprint.title' })} />
      <Content intro>
        <Title top="Gasser Partner">{title}</Title>
        {documentToReactComponents(content.json, options)}
        <Spacer size="small" />
        <Label>
          <FormattedMessage id="imprint.design" />
        </Label>
        <p>
          <a href="http://www.leoneming.com/">LEONE MING</a>
        </p>
        <br />
        <Label>
          <FormattedMessage id="imprint.development" />
        </Label>
        <p>
          <a href="https://www.a45.li/">A45 DIGITAL</a>
        </p>
        <Spacer size="small" />
      </Content>
    </Layout>
  )
}

export default ImprintPage

export const query = graphql`
  query ImprintQuery($i18nCurrentLocale: String!) {
    allContentfulPage(
      filter: {
        node_locale: { eq: $i18nCurrentLocale }
        contentful_id: { in: "2KJC3d8Xf9CrxtvZfIzoUk" }
      }
    ) {
      edges {
        node {
          contentful_id
          node_locale
          slug
          title
          content {
            json
          }
        }
      }
    }
  }
`

export const i18n = {
  en: '/imprint',
  de: '/impressum',
}
